.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content{
  margin: 50px;
}

@media only screen and (max-width: 450px) {
  .content {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 576px) {
  .label-column {
    text-align: left !important;
  }

  .form-buttons{
    display: grid;
    justify-content: center;
  }

  .form-buttons button{
    margin-bottom: 10px;
  }
}


@media only screen and (max-width: 317px) {
  
  .list-form-buttons button{
      margin-top: 10px;
  }

  .list-form-buttons{
    display: grid;
    justify-content: center;
  }

}

@media only screen and (min-width: 1300px) {
  .form-control, .form-select{
    width: 80%;
  }
}

.form-label{
  text-align: right;
}

.units-label{
  text-align: left;
}

.form-buttons{
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.form-buttons button {
  width: 155px;
  flex-shrink: 0;
}

.about-text{
  width: 50%;
  margin: 0 auto;
  font-size: x-large;
  text-align: justify;
}

.about-image{
  text-align: center;

}

.about-image img{
  width: 60%;

}

button{
  font-weight: 600 !important;
}

@media only screen and (max-width: 1200px) {
  .about-text{
    width: 80%;
  }
}

@media only screen and (max-width: 800px) {
  .about-text{
    width: 100%;
  }
}

.toggle-button{
  margin-left: 20px; 
  margin-right: 20px;
}

.navbar-logo{
  width: 500px;
  max-width: 95%;
  margin-left: 20px;
  margin-right: 0px;
}

@media only screen and (max-width: 450px) {
  .toggle-button{
    margin-left: 0px; 
  }

  .navbar-logo{
    margin-left: 0px;
    margin-right: 0px;
  }
}

.download-pdf a{
  text-decoration: none;
}

.download-pdf > a{
  color: #198754;
}

.download-pdf:hover > a{
  color: white;
}



