.meassuring-modal {
  width: 90%;
  max-width: 900px;
}

.meassuring-container {
  display: grid;
  grid-template-columns: 250px auto;
  /* display: flex; */
  gap: 15px;
  min-height: 350px;
}

@media only screen and (max-width: 600px) {
  
  .meassuring-chart {
    display: none;
  }
}

.meassuring-values {
  max-width: 250px;
}

.meassuring-chart canvas {
  max-width: 100%;
}

.meassured-value-container {
  align-items: baseline;
  display: flex;
  margin-bottom: 15px;
}

.meassuring-value-container {
  text-align: right;
}

.meassured-value-title {
  text-align: left;
  margin-top: 0.5rem;
  margin-bottom: 0;
  flex-grow: 1;
}

.meassured-value {
  font-size: 1.75rem;
  margin-right: 5px;
  font-weight: 600;
}

.meassured-value.messuring {
  font-size: 7rem;
  line-height: 6rem;
}

.meassured-unit {
  display: inline-block;
  width: 3.25rem;
  text-align: left;
}

.meassuring-values .form-label h5 {
  margin: 0;
  margin-top: 10px;
}

.meassuring-error {
  color: red;
  text-align: left;
  margin-top: 20px;
  font-size: 1.2rem;
}